import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import ls from "local-storage";
import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultButton from "../components/DefaultButton";
import { getDetails } from "../services/Transactions";
import DefaultContainer from "../components/DefaultContainer";
import DefaultTextField from "../components/DefaultTextField";
import qs from "query-string";
import Toast from "../components/Snackbar";
import DefaultHeader from "../components/DefaultHeader";
import { getReviewContent } from "../services/Reviews";
import { reviewInsert } from "../services/Reviews";
import { BoltRounded, SettingsPhone } from "@mui/icons-material";
import Rating from "@mui/material/Rating";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import StarIcon from "@mui/icons-material/Star";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {OverlayReview} from "../components/Overlay";
import Overlay from "../components/Overlay"
import { getAllByPartnerID } from "../services/Review";

export default function CreateReviewScreen(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [status, setStatus] = useState(0);
  const [msg, setMsg] = useState("");
  const location = useLocation();
  const [transactionID, setTransactionID] = useState("");
  const [trxDetail, setTrxDetail] = useState([]);
  const [partnerDetail, setPartnerDetail] = useState([]);
  const [ratingCount, setRatingCount] = React.useState(0);
  const [hover, setHover] = React.useState(-1);
  const [notes, setNotes] = useState("");
  const [arrChips, setArrChips] = useState([]);
  const [isAnonim, setIsAnonim] = useState(false);
  const [selected, setSelected] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [overlay, setOverlay] = useState(false)
  const [overlayAccessDenied, setOverlayAccessDenied] = useState(false)
  const [review, setReview] = useState([])
  const [overlayReviewed, setOverlayReviewed] = useState(false)
  const [partnerID, setPartnerID] = useState(false)

  const disabledButton = {
    bgcolor: "gray",
    disabled: true, 
  }

  const [buttonBehavior, setButtonBehavior] = useState(disabledButton)
  const [phone, setPhone] = useState("")

  const queryString = window.location.search;

  
  const enabledButton = {
    bgcolor: "#1FB0E6",
    disabled: false
  }

    // const [simpan, setSimpanReview] = useState();
    // const Item = styled(Paper)(({ theme }) => ({
    //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    //   ...theme.typography.body2,
    //   padding: theme.spacing(1),
    //   textAlign: "center",
    //   color: theme.palette.text.secondary,
    // }));
  
    // const setRatingCount = (ratingCount) => {
    //   if (ratingCount < 5 ? nRatingBad : nRatingGood)
    // }

  const labels = {
    1: "Sangat Buruk",
    2: "Buruk",
    3: "Baik",
    4: "Cukup Baik",
    5: "Sangat Baik",
  };

  const tagChip = {
    1: "Apa yang harus ditingkatkan?",
    2: "Apa yang harus ditingkatkan?",
    3: "Apa yang harus ditingkatkan?",
    4: "Apa yang harus ditingkatkan?",
    5: "Apa yang kamu suka?",
  };

  const handleChip = (value) => {
    if (
      arrChips.some((item) => {
        return value === item;
      })
    ) {
      const newArrChip = arrChips.filter((item, i) => {
        return item !== value;
      });
      setArrChips([...newArrChip]);
    } else {
      setArrChips([...arrChips, value]);
    }
  };

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [width, setWindowWidth] = useState(0);

  function getLabelText(ratingCount) {
    return `${ratingCount} Star${ratingCount !== 1 ? "s" : ""}, ${labels[ratingCount]
      }`;
  }

  function getRating(ratingCount) {
    return `${ratingCount} Star${ratingCount !== 1 ? "s" : ""}, ${tagChip[ratingCount]
      }`;
  }

  async function fetchAllReview(partnerDetail) {
    setLoading(true); 
    await getAllByPartnerID(ls.get("partnerID"), partnerDetail).then((response) => {
      setReview(response["reviews"])

      const filtered = response["reviews"].filter((item) => {
        return item.name === ls.get("redux_localstorage_simple").userData[ls.get("redux_localstorage_simple").userData.length - 1].name})

      if(filtered.length > 0){
        setButtonBehavior(disabledButton)
        setOverlayReviewed(true)
      }
      
      if(filtered.length === 0){
        setButtonBehavior(enabledButton)
      }
    });
  }


  async function createReview() {
    let dataReview = {
      partnerID: ls.get("partnerID"),
      transactionID: query.transactionID,
      rating: ratingCount,
      review: notes,
      attributes: arrChips.toString(),
      anonymous: isAnonim ? 1 : 0,
    };

    const response = await reviewInsert(dataReview);
    setIsToastOpen(true);
    setMsg(response.msg);
    setStatus(response.success);
    setTimeout(() => {
      setIsToastOpen(false);
    }, 100);
    if (response.success === 1) {
      setOverlay(true);
    }
  }

  const HandleMerchant = () => {
    // 1. Menghilangkan karakter selain angka
    // let formatted = ls.get("sfPartner").phone.replace(/\D/g, "");
    let formatted = partnerDetail.phone.replace(/\D/g, "");

    // 2. Menghilangkan angka 0 di depan (prefix)
    //    Kemudian diganti dengan 62
    if (formatted.startsWith("0")) {
      formatted = "62" + formatted.substr(1);
    }
    window.open("http://wa.me/" + formatted, "_blank");
  };

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  function fetchReviewContent(partnerDetail) {
    setLoading(true);
    getReviewContent(partnerDetail).then((response) => {
      setPartnerDetail(response.partnerDetail);

      setLoading(false);
      setPhone(response.phone)
      setPartnerID(response.partnerDetail.id)
      ls.set("partnerID", response.partnerDetail.id)
    });
  }

  const [renderCounter, setRenderCounter] = useState(0)

  useEffect(() => {

    if(ls.get("sfIsReview") === "1"){
      window.addEventListener('popstate', (e) => {
          setTimeout(()=>
          {
              navigate(`/?partnerID=${ls.get("partnerID") ? ls.get("partnerID") : partnerID}&tableID=null`)
          }, 100
          )
      })
    }

    updateDimensions();
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });


    setTransactionID(query.transactionID);
    fetchDetails(query.transactionID);
    fetchAllReview(query.transactionID);
    fetchReviewContent(query.transactionID);

    if(query.partnerID === undefined){
      window.history.pushState({}, '', `/?partnerID=${ls.get("partnerID") ? ls.get("partnerID") : partnerID}&tableID=null`);
      navigate(`/create-review${queryString}&partnerID=${ls.get("partnerID")}`)
    }

    //cek dulu redux user / token
    if (ls.get("sfToken") === null) {
      navigate(`/create-review${queryString}&partnerID=${ls.get("partnerID")}`)
    }

    if(ls.get("sfPartner") === null){
      ls.set(
        "sfIsReview",
        "1"
      )
    }

    if(!ls.get("redux_localstorage_simple") || (ls.get("sfToken") === null || ls.get("sfToken").includes("dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz")
    || ls.get("sfToken").includes("V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0=")
    )){
      navigate(`/auth?partnerID=${partnerID}`,{state:{to:`/create-review${queryString}`}})
    }else{
      if(ls.get("sfReview")){
        setButtonBehavior(ls.get("sfReview"))
      }
      
      if(ls.get("redux_localstorage_simple").userData[ls.get("redux_localstorage_simple").userData.length - 1].phone){
        if(ls.get("redux_localstorage_simple").userData[ls.get("redux_localstorage_simple").userData.length - 1].phone !== phone){
          console.log("hit this")
          setButtonBehavior(disabledButton)
          setOverlayAccessDenied(true)
        } else {
          setButtonBehavior(enabledButton)
          setOverlayAccessDenied(false)
        }
      } else{
        navigate(`/auth?partnerID=${partnerID}`,{state:{to:'/history'}})
      }
    }

    setRenderCounter(renderCounter + 1)

    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions)
    }
  }, [transactionID, phone]);

  // useEffect(() => {
  //   fetchDetails(query.transactionID);
  // }, []);

  async function fetchDetails(id) {
    setLoading(true);
    await getDetails({ id: id }).then((response) => {
      setLoading(false);

      let dataDetails = response.details;
      let variants = "";
      let subtotalItem = 0;
      let specDiscItem = 0;
      let chargeT = 0;
      let taxT = 0;
      let serviceT = 0;
      let totalSub = 0;
      let specialDiscT = 0;
      let total = 0;

      if (dataDetails.length > 0) {
        dataDetails.forEach((item) => {
          if (item.variant) {
            variants = item.variant;
            variants = variants.replace("[", "{");
            variants = variants.slice(0, -1) + "}";
            variants = JSON.parse(variants.replace(/'/gi, '"'));
            variants = variants.variant;
          }
          let variantString = [];

          if (variants.length > 0) {
            variants.forEach((item) => {
              item.detail.forEach((val) => {
                variantString.push(val.name);
              });
            });
          }
          item.variant =
            variantString.length > 0
              ? variantString.toString().replaceAll(",", ", ")
              : "";

          subtotalItem += parseInt(item.harga);
        });

        totalSub = subtotalItem - specDiscItem;
        chargeT = (totalSub * response.chargeUR) / 100;
        serviceT = (totalSub * response.service) / 100;
        taxT = Math.ceil(((totalSub + serviceT) * response.tax) / 100);
        specialDiscT = (totalSub * response.specialDiscount) / 100;
        total = totalSub + chargeT + taxT + serviceT;
      }

      setDetails(dataDetails);
    });
  }

  let itemCard =
    details &&
    details.map((x, i) => {
      return (
        <Grid
          container
          style={{
            marginBottom: 10,
            // borderTop: "0.2px solid #c0c0c0",
            // borderBottom: "0.2px solid #c0c0c0",
            borderOpacity: 0.5,
          }}
          key={i}
        >
          <Grid item xs={3}>
            <img
              src={
                details[i].thumbnail === ""
                  ? "../assets/img/image-not-found.png"
                  : details[i].thumbnail
              }
              style={{
                borderRadius: 100,
                objectFit: "cover",
                width: 65,
                height: 65,
              }}
              alt="product"
              onError={(e)=>{
                e.target.src = "../assets/img/image-not-found.png"
              }}
            />
          </Grid>
          <Grid item xs={9} textAlign="left">
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Box>
                  <Typography>
                    {" "}
                    {details[i].nama}
                    <Box>
                      <Typography align="right">
                        {" "}
                        {" x" + details[i].qty}
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography style={{overflowWrap:"break-word"}}>
                  {details[i].variant && <>{details[i].variant}</>}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: "gray",
                    width: "65%",
                    fontStyle: "italic",
                  }}
                >
                  {" "}
                  {details[i].notes}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });

  return overlayAccessDenied ? (
    <Overlay warning={true} routeToGo={`${ ls.get("sfIsReview") ? "" : "/history" }`} routeLabel="Kembali" message="Anda Tidak Memiliki Akses Di Transaksi Ini"/> 
  ) : 
  overlayReviewed ? 
  <Overlay warning={false} check={true} routeToGo={`${ ls.get("sfIsReview") ? "" : "/history" }`} routeLabel="Kembali" message="Anda Sudah Memberikan Ulasan Untuk Transaksi Ini"/>
  :
  !overlay ? 
  (
    <DefaultContainer bgColor={"primary.main"} loading={loading}>
      <Toast isOpen={isToastOpen} message={msg} status={status} />
      {loading ? (
        <Box
          className="test"
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid black",
          }}
        >
          {/* <SkeletonOnboard /> */}
        </Box>
      ) : (
        <Box
          style={{
            height: "100vh",
            background: "#fff",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <DefaultHeader
            align="center"
            title={"Nilai Pesanan Kamu"}
            url={ls.get("sfIsReview") === "1" ? "reload" : "/history"}
            style={{
              display: "flex",
            }}
            onClick={(e) => {
              navigate("/history-detail?transactionID=" + query.transactionID, {
                state: { partnerDetail: trxDetail },
              });
            }}
          />
          <Grid
            align="center"
            item
            xs={3}
            style={{
              paddingTop: 10,
            }}
          >
            <img
              alt=""
              src={partnerDetail.image ? partnerDetail.image : "/assets/img/image-not-found.png"}
              style={{
                borderRadius: 100,
                objectFit: "cover",
                width: 150,
                height: 150,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingTop: 15,
              paddingLeft: 15,
              paddingRight: 15,
              paddingBottom: 5,
              align: "center",
            }}
          >
            <Typography
              align="center"
              style={{
                fontWeight: 500,
                fontSize: 20,
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {partnerDetail.name}
            </Typography>
            <Typography
              align="center"
              justifyContent="center"
              style={{
                fontSize: 14,
                color: "#6c757d",
              }}
            >
              {partnerDetail.address}
            </Typography>
            <Typography fontWeight="bold">Pesanan Kamu</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 15 }}>
            {loading ? (
              <center>
                {/* //buat shimmer card */}
                <CircularProgress />
              </center>
            ) : details ? (
              itemCard
            ) : (
              <div>notfound</div>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 5,
              marginLeft: 65,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: 200,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Rating
                name="hover-feedback"
                value={ratingCount}
                precision={1}
                size="large"
                getLabelText={getLabelText}
                getRating={getRating}
                onChange={(event, value) => {
                  setRatingCount(value);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
                emptyIcon={
                  <StarIcon
                    style={{ marginBottom: 5, opacity: 0.55 }}
                    fontSize="inherit"
                  />
                }
              />
            </Box>
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "left",
              justifyContent: "left",
              paddingBottom: 15,
            }}
          >
            {ratingCount !== null && (
              <Box sx={{ ml: 2 }}>
                {labels[hover !== -1 ? hover : ratingCount]}
              </Box>
            )}
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "left",
              justifyContent: "left",
              fontWeight: "bold",
            }}
          >
            {ratingCount !== null && (
              <Box id="tagChip" sx={{ ml: 2 }}>
                {tagChip[hover !== -1 ? hover : ratingCount]}
              </Box>
            )}
          </Grid>

          {                
           ratingCount && ratingCount > 0 ?
          <Grid
            item
            xs={12}
            style={{
              marginTop: 5,
            }}
          >
            <Stack direction="row" spacing={1} marginBottom={1}>
              <Chip
                label="Waktu Proses"
                value="Waktu Proses"
                key={0}
                onClick={(e) => handleChip(e.target.innerText)}
                onDelete={selected && (() => { })}
                color={
                  arrChips.some((item) => {
                    return item === "Waktu Proses";
                  })
                    ? "secondary"
                    : "default"
                }
              />
              <Chip
                label="Rasa"
                value="Rasa"
                key={1}
                onClick={(e) => handleChip(e.target.innerText)}
                onDelete={selected && (() => { })}
                color={
                  arrChips.some((item) => {
                    return item === "Rasa";
                  })
                    ? "secondary"
                    : "default"
                }
              />
              <Chip
                label="Kebersihan"
                value="Kebersihan"
                key={2}
                onClick={(e) => handleChip(e.target.innerText)}
                onDelete={selected && (() => { })}
                color={
                  arrChips.some((item) => {
                    return item === "Kebersihan";
                  })
                    ? "secondary"
                    : "default"
                }
              />
              <Chip
                label="Staff"
                value="Staff"
                key={3}
                onClick={(e) => handleChip(e.target.innerText)}
                onDelete={selected && (() => { })}
                color={
                  arrChips.some((item) => {
                    return item === "Staff";
                  })
                    ? "secondary"
                    : "default"
                }
              />
            </Stack>
            <Stack direction="row" spacing={1} marginBottom={1}>
              <Chip
                label="Wifi"
                value="Wifi"
                key={4}
                onClick={(e) => handleChip(e.target.innerText)}
                onDelete={selected && (() => { })}
                color={
                  arrChips.some((item) => {
                    return item === "Wifi";
                  })
                    ? "secondary"
                    : "default"
                }
              />
              <Chip
                label="Suasana"
                value="Suasana"
                key={5}
                onClick={(e) => handleChip(e.target.innerText)}
                onDelete={selected && (() => { })}
                color={
                  arrChips.some((item) => {
                    return item === "Suasana";
                  })
                    ? "secondary"
                    : "default"
                }
              />
              <Chip
                label="Lain-lain"
                value="Lain-lain"
                key={6}
                onClick={(e) => handleChip(e.target.innerText)}
                onDelete={selected && (() => { })}
                color={
                  arrChips.some((item) => {
                    return item === "Lain-lain";
                  })
                    ? "secondary"
                    : "default"
                }
              />
            </Stack>
          </Grid>
          :
          <></>
          }
          <Grid item xs={12} marginTop={2}>
            <DefaultTextField
              label="Ceritakan pengalamanmu (Opsional)"
              id="input"
              style={{
                display: "flex",
              }}
              onChange={(event) => setNotes(event.target.value)}
            />
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox onChange={(event) => setIsAnonim(!isAnonim)} />
                  }
                  label="Anonim"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <DefaultButton
                title="Simpan"
                variant="contained"
                id="Simpan"
                value="Simpan"
                fullWidth
                disabled={buttonBehavior.disabled || !ratingCount || ratingCount < 1}
                style={{ color: "white", paddingTop: 2, backgroundColor: buttonBehavior.bgcolor }}
                onClick={async () => {
                  if(transactionID){
                    createReview()
                  }
                  else{
                    navigate("/history", {replace: true});
                  }

                  ls.remove("sfReview")
                }}
              ></DefaultButton>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingBottom: 10,
              }}
            >
              <Button
                variant="outlined"
                id="Hubungi Merchant"
                value="Hubungi Merchant"
                fullWidth
                style={{
                  display: "flex",
                  marginTop: 10,
                  marginBottom: 10,
                }}
                onClick={HandleMerchant}
              >
                Hubungi Merchant
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </DefaultContainer>
  ) :
  (
    <OverlayReview/>
  ) 
}
