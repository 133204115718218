import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultContainer from "../components/DefaultContainer";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  decreaseQuantity,
  deleteAllFromCart,
  increaseQuantity,
} from "../config/redux/actions/cartActions";
import { connect } from "react-redux";
import CartItemCard from "../components/CartItemCard";
import { calculate, create, getProgramActive } from "../services/Transactions";
import MoneyFormat from "../components/MoneyFormat";
import DefaultButton from "../components/DefaultButton";
import SkeletonCalculate from "../components/SkeletonCalculate";
import DefaultHeader from "../components/DefaultHeader";
import ls from "local-storage";
import Toast from "../components/Snackbar";
import Confirmation from "../components/Confirmation";
import Warning from "../components/Warning";
import SmartWaiter from "../components/SmartWaiter";
import { Modal } from "@material-ui/core";
import { useRef } from "react";
import { flushSync } from "react-dom";
import Overlay from "../components/Overlay";
import { getSpecialDiscount } from "../services/Partners";
import { Troubleshoot } from "@mui/icons-material";
import Alert from "@mui/material/Alert";

const CartScreen = ({
  deleteAllFromCart,
  cartData,
  userDetail,
  decreaseQuantity,
  increaseQuantity,
}) => {
  const [calculateResponse, setCalculateResponse] = useState([]);
  const [loadingCalculate, setLoadingCalculate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [msg, setMsg] = useState("");
  const [isOpeningHour, setIsOpeningHour] = useState(false);
  const [msgToast, setMsgToast] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [programData, setProgramData] = useState("");
  const [isTakeaway, setIsTakeaway] = useState(0);
  const [chooseTransactionType, setChooseTransactionType] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState("");
  const [dynamicRefBottom, setDynamicRefBottom] = useState("");
  const [paymentWay, setPaymentWay] = useState(0);
  const [choosePaymentWay, setChoosePaymentWay] = useState(false);
  const [isMembership, setIsMembership] = useState(true);
  const [idTransaction, setIdTransaction] = useState(0);
  const [specialDiscount, setSpecialDiscount] = useState(0);
  const [choosenTrxType, setChoosenTrxType] = useState(false);
  const [recalculate, setRecalculate] = useState(0);
  const [renderCounter, setRenderCounter] = useState(0);
  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();
  const seconds = new Date().getSeconds();
  const [orderCheck, setOrderCheck] = useState(false);
  const [openOrderCheck, setOpenOrderCheck] = useState(false);

  const dynamicHeightRef = useRef();
  const dynamicBottomRef = useRef();

  const timeNow = `${hours > 9 ? hours : "0" + hours}:${
    minutes > 9 ? minutes : "0" + minutes
  }:${seconds > 9 ? seconds : "0" + seconds}`;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (renderCounter === 0) {
      if (
        ls.get("dataTrx") &&
        ls.get("requested") === 1 &&
        (ls.get("sfPartner").is_temporary_qr === 0 ||
          ls.get("sfPartner").is_temporary_qr === "0") &&
        (ls.get("sfPartner").open_bill === 0 ||
          ls.get("sfPartner").open_bill === "0")
      ) {
        createTrx(0, "", 0, ls.get("dataTrx"));
        setChoosenTrxType(true);
      } else {
        calculateTrx();
      }
      setRenderCounter(renderCounter + 1);
    }

    if (
      ls.get("sfOpenHour") &&
      ls.get("sfOpenHour") < timeNow &&
      timeNow > ls.get("sfCloseHour")
    ) {
      setIsOpeningHour(false);
      setMsgToast("Mohon maaf cafe sudah tutup, silahkan coba kembali besok");
      return;
    }

    if (ls.get("sfOpenHour") && ls.get("sfOpenHour") > timeNow) {
      setIsOpeningHour(false);
      setMsgToast("Mohon maaf cafe belum buka");
      return;
    }

    if (ls.get("sfShift") === "1") {
      if (
        ls.get("sfOpenHour") &&
        ls.get("sfOpenHour") < timeNow &&
        timeNow < ls.get("sfCloseHour")
      ) {
        setIsOpeningHour(true);
      }
    } else {
      if (
        ls.get("sfOpenHour") &&
        ls.get("sfOpenHour") < timeNow &&
        timeNow > ls.get("sfLastOrder") &&
        timeNow < ls.get("sfCloseHour")
      ) {
        setIsOpeningHour(false);
        setMsgToast(
          "Mohon maaf waktu pemesanan anda melebihi waktu last order, silahkan coba kembali besok"
        );
        return;
      } else {
        setMsgToast("Resto belum memulai shift. Mohon hubungi kasir");
        return;
      }
    }

    if (choosenTrxType === false && recalculate === 0) {
      setDynamicHeight(dynamicHeightRef.current.clientHeight);
      setDynamicRefBottom(dynamicBottomRef.current.clientHeight);
    }
  }, [cartData, isTakeaway]);

  function back() {
    if (idTransaction === 0) {
      navigate("/order");
    } else {
      navigate("/order-received", {
        state: {
          membership: isMembership,
          id: idTransaction,
          to: "/order",
          phone: ls.get("redux_localstorage_simple").userData[0].phone,
          ...location.state,
        },
      });
    }

    // if(isMembership === true){
    //   navigate("/order");
    // } else {
    //   navigate("/membership", {state: {to: "/order", phone:ls.get("redux_localstorage_simple").userData[0].phone}});
    // }
  }

  async function createTrx(takeaway, response, paymentWay, dataFromLs) {
    setIsBtnDisabled(true);

    let data = {
      partnerID: ls.get("partnerID"),
      paymentMethod: "11",
      tableCode: ls.get("tableID"),
      paymentWay: response?.paymentWay ?? paymentWay,
      id_voucher: "",
      id_voucher_redeemable: "",
      voucher_type: "",
      is_takeaway: takeaway,
      // detail: cartData,
      detail: response?.detail ?? calculateResponse.detail,
      foodcourtID: "",
      user_address_id: "",
      rate_id: "",
      program_id: response?.program_id ?? calculateResponse.program_id,
      program_discount:
        response?.program_discount ?? calculateResponse.program_discount,
      program_payment_method:
        response?.program_payment_method ??
        calculateResponse.program_payment_method,
      program_title: response?.program_title ?? calculateResponse.program_title,
      promo: 0,
      pax: ls.get("pax") ?? 1,
      total: response?.total ?? calculateResponse.total,
      subtotal: response?.subtotal ?? calculateResponse.subtotal,
      rounding: response?.roundingDif ?? calculateResponse.roundingDif,
      totalAfterRounding:
        ls.get("sfPartner").is_rounding === "1"
          ? response?.rounding ?? calculateResponse.rounding
          : response?.total ?? calculateResponse.total,
      // is_delivery: 0,
      is_queue: ls.get("sfPartner").is_queue_tracking,
      // is_insurance: isInsurance,
      notes: "",
      isMembership: isMembership,
      is_temporary_qr: ls.get("trxFromQR") ? "1" : "0",
      trx_id_qr: ls.get("trxFromQR") ?? "",
      // delivery_fee: deliveryFee,
      // subTotal: subTotal,
      // total_program: totalProgram,
      diskon_spesial: specialDiscount,
      // delivery_detail: deliveryDetail,
    };

    const openBill = ls.get("sfPartner").open_bill;
    if (
      (openBill === "1" ||
        ls.get("sfPartner").is_temporary_qr == 1 ||
        ls.get("sfPartner").is_temporary_qr == "1") &&
      (paymentWay === 0 || response?.paymentWay === 0)
    ) {
      if (
        ls.get("sfPartner").is_temporary_qr == 0 ||
        ls.get("sfPartner").is_temporary_qr == "0"
      ) {
        ls.set("dataTrx", data);
        ls.set("requested", 1);
      }

      const response = await create(data);

      if (response.is_membership === false) {
        setIsMembership(false);
      }
      if (response.success === 1) {
        if (
          ls.get("sfPartner").is_temporary_qr == 0 ||
          ls.get("sfPartner").is_temporary_qr == "0"
        ) {
          ls.remove("requested");
          ls.remove("dataTrx");
        }

        deleteAllFromCart();

        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);
        setIdTransaction(response.transaction_id);

        // setTimeout(() => {
        navigate("/order-success", {
          replace: true,
          state: { from: "/order" },
        });
        // }, 1000);
      } else {
        setRecalculate((prev) => prev + 1);
        setChoosenTrxType(false);
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);

        setTimeout(() => {
          setIsBtnDisabled(false);
          setIsOpen(false);
        }, 100);
      }
    } else {
      navigate("/payment", { state: data });
    }
  }

  async function fetchSpecialDiscount() {
    const response = await getSpecialDiscount(ls.get("sfPartner").id);
    setSpecialDiscount(response.max_discount);
    return response.max_discount;
  }

  async function calculateTrx() {
    setLoadingCalculate(true);
    let data;
    const specialDisc = await fetchSpecialDiscount();
    const res = await getProgramActive(ls.get("sfPartner").id);

    if (res.success === 1) {
      data = res.data;
      setProgramData(data);
    }

    let calculateData = {
      is_program_discount: data && data.master_program_id === "2" ? "1" : "0",
      program_discount_id:
        data && data.master_program_id === "2" ? data.id : "",
      program_discount_name:
        data && data.master_program_id === "2" ? data.title : "",
      masterID: "1",
      partnerID: ls.get("partnerID"),
      id_voucher: "",
      id_voucher_redeemable: "",
      voucher_type: "",
      diskon_spesial: specialDisc,
      transaction_type: 0,
      is_delivery: "0",
      delivery_detail: "",
      detail: cartData,
      is_rounding: ls.get("sfPartner").is_rounding,
      rounding_digits: ls.get("sfPartner").rounding_digits,
      rounding_down_below: ls.get("sfPartner").rounding_down_below,
    };

    await calculate(calculateData).then((response) => {
      setCalculateResponse(response);

      if (
        location.state?.isTakeaway === 0 ||
        location.state?.isTakeaway === 1
      ) {
        setLoadingCalculate(false);
        if (response)
          createTrx(
            location.state.isTakeaway,
            response,
            location.state.paymentWay
          );
      } else {
        setLoadingCalculate(false);
      }
    });
  }

  // useEffect(() => {
  //   calculateTrx();

  //   if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow &&
  //     timeNow > ls.get("sfCloseHour")
  //     ) {
  //       setIsOpeningHour(false);
  //       setMsgToast(
  //         "Mohon maaf cafe sudah tutup, silahkan coba kembali besok"
  //       );
  //       return
  //   }

  //   if(ls.get("sfOpenHour") && ls.get("sfOpenHour") > timeNow){
  //     setIsOpeningHour(false);
  //     setMsgToast("Mohon maaf cafe belum buka");
  //     return
  //   }

  //   if (ls.get("sfShift") === "1") {
  //     if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow && timeNow < ls.get("sfCloseHour")) {
  //       setIsOpeningHour(true);
  //     }
  //   } else {
  //     if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow &&
  //     timeNow > ls.get("sfLastOrder") && timeNow < ls.get("sfCloseHour")
  //     ) {
  //       setIsOpeningHour(false);
  //       setMsgToast(
  //         "Mohon maaf waktu pemesanan anda melebihi waktu last order, silahkan coba kembali besok"
  //       );
  //     return
  //     } else {
  //       setMsgToast("Resto belum memulai shift. Mohon hubungi kasir");
  //       return
  //     }
  //   }

  //   if(choosenTrxType === false && recalculate === 0){
  //     setDynamicHeight(dynamicHeightRef.current.clientHeight)
  //     setDynamicRefBottom(dynamicBottomRef.current.clientHeight)
  //   }

  // }, [cartData, isTakeaway, choosenTrxType]);

  let items =
    cartData &&
    cartData.map((x, i) => {
      return (
        <CartItemCard
          productDetails={cartData[i]}
          index={i}
          key={i}
          decreaseQuantity={decreaseQuantity}
          increaseQuantity={increaseQuantity}
        />
      );
    });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenOrderCheck(false);
  };

  const handleClickOk = () => {
    setMsg("Berhasil Hapus");
    setIsOpen(true);
    setStatus(1);

    deleteAllFromCart();

    setTimeout(() => {
      back();
    }, 1000);

    setOpen(false);
  };

  const handleOrderCheck = async () => {
    if (!ls.get("tableID")) {
      setOrderCheck(true);
      setOpenOrderCheck(true);
    } else {
      setChooseTransactionType(true);
    }
  };

  function createButton(payment) {
    if (
      userDetail !== null &&
      (!(ls.get("sfToken") === null) ||
        !ls
          .get("sfToken")
          ?.includes(
            "dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz"
          ) ||
        !ls
          .get("sfToken")
          ?.includes(
            "V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0="
          )) &&
      ls.get("redux_localstorage_simple")?.userData.length > 0
    ) {
      createTrx(isTakeaway, "", payment);
    } else {
      navigate("/auth", {
        replace: true,
        state: { to: "/cart", isTakeaway: isTakeaway, paymentWay: payment },
      });
    }
  }

  function createButtonTakeaway(payment, takeaway) {
    if (
      userDetail !== null &&
      (!(ls.get("sfToken") === null) ||
        !ls
          .get("sfToken")
          ?.includes(
            "dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz"
          ) ||
        !ls
          .get("sfToken")
          ?.includes(
            "V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0="
          )) &&
      ls.get("redux_localstorage_simple")?.userData.length > 0
    ) {
      createTrx(takeaway, "", payment);
    } else {
      navigate("/auth", {
        replace: true,
        state: { to: "/cart", isTakeaway: takeaway, paymentWay: payment },
      });
    }
  }

  const data = window.innerHeight > window.innerWidth ? 100 : 125;

  return (
    <>
      {orderCheck && (
        <Dialog
          open={openOrderCheck}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Terjadi Kesalahan"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Silakan scan kembali QR Meja
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Tutup</Button>
          </DialogActions>
        </Dialog>
      )}
      {choosenTrxType &&
      (ls.get("sfPartner").open_bill === "1" ||
        ls.get("sfPartner").is_temporary_qr === "1" ||
        ls.get("sfPartner").is_temporary_qr === 1) ? (
        <Overlay message="Transaksi Sedang Diproses" loading={true} />
      ) : (
        <DefaultContainer loading={loadingCalculate}>
          <Toast isOpen={isOpen} message={msg} status={status} />

          <Dialog
            open={chooseTransactionType}
            PaperProps={{
              style: { borderRadius: "12px" },
            }}
          >
            <Box
              style={{
                height: "auto",
                width: "240px",
                backgroundColor: "white",
                zIndex: 9999,
                display: "flex",
                flexWrap: "wrap",
                alignContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "10%",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => {
                    setChooseTransactionType(false);
                  }}
                  style={{
                    paddingTop: "2.5vh",
                  }}
                >
                  X
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "10%",
                  justifyContent: "center",
                  alignItems: "baseline",
                  paddingTop: "1vh",
                  paddingBottom: "1.5vh",
                }}
              >
                <Typography>Pilih Mana?</Typography>
              </div>
              <Button
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  paddingTop: "2vh",
                  paddingBottom: "2vh",
                  paddingRight:
                    window.innerHeight > window.innerWidth ? "7vw" : "2vw",
                  paddingLeft:
                    window.innerHeight > window.innerWidth ? "4vw" : "1.5vw",
                  justifyContent: "space-between",
                }}
                disabled={
                  choosenTrxType === true ||
                  (calculateResponse?.program_discount > 0 &&
                  programData?.transaction_type?.some(
                    (item) => item.label === "Dine In"
                  )
                    ? false
                    : JSON.stringify(programData) === JSON.stringify([]) ||
                      programData === "" ||
                      calculateResponse?.program_discount === 0
                    ? false
                    : true)
                }
                onClick={(e) => {
                  setChooseTransactionType(false);
                  setIsTakeaway(0);
                  if (
                    ls.get("sfPartner").open_bill === "1" ||
                    ls.get("sfPartner").is_temporary_qr === "1"
                  ) {
                    setChoosePaymentWay(false);
                    createButton(0);
                    setChoosenTrxType(true);
                  } else {
                    setChoosePaymentWay(true);
                  }

                  // clearTimeout(timeout)
                }}
              >
                <div
                  style={{
                    width: "40%",
                    textAlign: "left",
                  }}
                >
                  <img src={"/assets/img/menu.png"} alt="dine-in" width={70} />
                </div>
                <div style={{ width: "60%", textAlign: "right" }}>
                  Dine In
                  {calculateResponse?.program_discount > 0 &&
                  programData?.transaction_type?.some(
                    (item) => item.label === "Dine In"
                  ) ? (
                    <></>
                  ) : JSON.stringify(programData) === JSON.stringify([]) ||
                    programData === "" ||
                    calculateResponse?.program_discount === 0 ? (
                    <></>
                  ) : (
                    <Typography
                      style={{
                        color: "red",
                        fontSize: "0.6rem",
                        textTransform: "none",
                      }}
                    >
                      Tipe transaksi tidak dapat digunakan untuk jenis
                      promo/diskon yang didapat
                    </Typography>
                  )}
                </div>
              </Button>
              <Button
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  paddingBottom: "3vh",
                  paddingRight:
                    window.innerHeight > window.innerWidth ? "7vw" : "2vw",
                  paddingLeft:
                    window.innerHeight > window.innerWidth ? "7.5vw" : "2.5vw",
                  justifyContent: "space-between",
                }}
                disabled={
                  choosenTrxType === true ||
                  ls.get("sfPartner").is_takeaway === "0" ||
                  ls.get("sfPartner").is_takeaway === 0 ||
                  (calculateResponse?.program_discount > 0 &&
                  programData?.transaction_type?.some(
                    (item) => item.label === "Take Away"
                  )
                    ? false
                    : JSON.stringify(programData) === JSON.stringify([]) ||
                      programData === "" ||
                      calculateResponse?.program_discount === 0
                    ? false
                    : true)
                }
                onClick={() => {
                  setChoosenTrxType(true);
                  setChooseTransactionType(false);
                  setIsTakeaway(1);
                  // if(ls.get('sfPartner').open_bill === "1"){
                  setChoosePaymentWay(false);
                  createButtonTakeaway(1, 1);
                  // } else {
                  //   setChoosePaymentWay(true)
                  // }
                }}
              >
                <div
                  style={{
                    width: "40%",
                    marginLeft: "-5px",
                  }}
                >
                  <img
                    src={"/assets/img/take-away.png"}
                    alt="take-away"
                    width={70}
                  />
                </div>
                <div style={{ width: "60%", textAlign: "right" }}>
                  Take Away
                  {calculateResponse?.program_discount > 0 &&
                  programData?.transaction_type?.some(
                    (item) => item.label === "Take Away"
                  ) ? (
                    <></>
                  ) : JSON.stringify(programData) === JSON.stringify([]) ||
                    programData === "" ||
                    calculateResponse?.program_discount === 0 ? (
                    <></>
                  ) : (
                    <Typography
                      style={{
                        color: "red",
                        fontSize: "0.6rem",
                        textTransform: "none",
                      }}
                    >
                      Tipe transaksi tidak dapat digunakan untuk jenis
                      promo/diskon yang didapat
                    </Typography>
                  )}
                </div>
              </Button>
            </Box>
          </Dialog>

          <Dialog
            open={choosePaymentWay}
            PaperProps={{
              style: { borderRadius: "12px" },
            }}
          >
            <Box
              style={{
                height: "auto",
                width: "265px",
                backgroundColor: "white",
                zIndex: 9999,
                display: "flex",
                flexWrap: "wrap",
                alignContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "10%",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => {
                    setChoosePaymentWay(false);
                  }}
                  style={{
                    paddingTop: "2.5vh",
                  }}
                >
                  X
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "10%",
                  justifyContent: "center",
                  alignItems: "baseline",
                  paddingTop: "1vh",
                  paddingBottom: "1.5vh",
                }}
              >
                <Typography>Mau Bayar Di Mana?</Typography>
              </div>
              <Button
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  paddingTop: "2vh",
                  paddingBottom: "2vh",
                  paddingRight:
                    window.innerHeight > window.innerWidth ? "7vw" : "2vw",
                  paddingLeft:
                    window.innerHeight > window.innerWidth ? "4vw" : "1.5vw",
                  marginBottom: "2vh",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  setChooseTransactionType(false);
                  setPaymentWay(0);
                  createButton(0);
                }}
              >
                <div
                  style={{
                    width: "40%",
                    textAlign: "left",
                  }}
                >
                  <img
                    src={"/assets/img/cashier.png"}
                    alt="dine-in"
                    width={70}
                  />
                </div>
                Bayar Di Kasir
              </Button>
              <Button
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  paddingBottom: "3vh",
                  paddingRight:
                    window.innerHeight > window.innerWidth ? "7vw" : "2vw",
                  paddingLeft:
                    window.innerHeight > window.innerWidth ? "7.5vw" : "2.5vw",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  setChooseTransactionType(false);
                  setPaymentWay(1);
                  createButton(1);
                }}
              >
                <div
                  style={{
                    width: "40%",
                    marginLeft: "-15px",
                  }}
                >
                  <img
                    src={"/assets/img/handphone.png"}
                    alt="take-away"
                    width={70}
                  />
                </div>
                <div style={{ width: "60%", textAlign: "right" }}>
                  Bayar Di Handphone
                </div>
              </Button>
            </Box>
          </Dialog>

          <Confirmation
            open={open}
            onOk={handleClickOk}
            onClose={handleClose}
            title={"Hapus Semua Item"}
            confirmMsg={
              "Anda yakin akan ingin menghapus semua item pada cart? Semua item yang terhapus tidak dapat dikembalikan"
            }
          />
          <Box>
            {/* <Grid style={{ margin: 15 }}> */}
            <Grid>
              <Grid container>
                {isOpeningHour === false && <Warning msg={msgToast} />}
                <Grid
                  item
                  xs={12}
                  style={{
                    background: "#fff",
                    padding: 15,
                    textAlign: "center",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <DefaultHeader
                    title={"Cart"}
                    trailing={
                      <DeleteOutlineIcon
                        onClick={() => {
                          handleClickOpen();
                        }}
                      />
                    }
                    url={"/order"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: "0px 15px",
                    marginTop: 15,
                    marginBottom: dynamicRefBottom + data,
                  }}
                >
                  <div ref={dynamicHeightRef}>
                    {cartData && cartData.length > 0 ? items : back()}

                    <SmartWaiter cartData={cartData} />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  textAlign="left"
                  style={{
                    paddingTop: 10,
                    paddingBottom: 15,
                    paddingLeft: 15,
                    paddingRight: 15,
                    position: "fixed",
                    bottom: 0,
                    width: 480,
                    backgroundColor: "#fff",
                  }}
                >
                  {loadingCalculate ? (
                    <>
                      <SkeletonCalculate />
                    </>
                  ) : (
                    <Grid ref={dynamicBottomRef}>
                      {specialDiscount != 0 && (
                        <Grid
                          bgcolor={"#1FB0E6"}
                          display={"flex"}
                          justifyContent={"space-between"}
                          borderRadius={"0.5rem 0.5rem 0 0"}
                          padding={1}
                        >
                          <Typography color={"#fff"} fontWeight={"500"}>
                            Diskon Spesial
                          </Typography>
                          <Typography color={"#fff"} fontWeight={"500"}>
                            {`${specialDiscount}%`}
                          </Typography>
                        </Grid>
                      )}
                      <Typography fontWeight={"500"}>Detail Tagihan</Typography>
                      <Grid container style={{ marginTop: 5 }}>
                        <Grid item xs={6} textAlign="left">
                          Subtotal
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <MoneyFormat
                            value={calculateResponse?.subtotal ?? 0}
                          />
                        </Grid>
                      </Grid>
                      {specialDiscount != 0 && (
                        <Grid container style={{ marginTop: 5 }}>
                          <Grid item xs={6} textAlign="left">
                            Diskon Spesial
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <MoneyFormat
                              value={
                                (calculateResponse?.diskon_spesial ?? 0) * -1
                              }
                            />
                          </Grid>
                        </Grid>
                      )}
                      {(calculateResponse?.program_discount ?? 0) !== 0 ? (
                        <Grid container style={{ marginTop: 5 }}>
                          <Grid item xs={6} textAlign="left">
                            {"Diskon (" +
                              calculateResponse?.program_title +
                              ")"}
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <span
                              style={{
                                color: "#68BB59",
                              }}
                            >
                              {"- "}
                              <MoneyFormat
                                value={calculateResponse?.program_discount ?? 0}
                              />
                            </span>
                          </Grid>
                        </Grid>
                      ) : (
                        <div></div>
                      )}{" "}
                      {(calculateResponse?.percent_service ?? "0") !== "0" ? (
                        <Grid container style={{ marginTop: 5 }}>
                          <Grid item xs={6} textAlign="left">
                            {"Service (" +
                              calculateResponse?.percent_service +
                              "%)"}
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <MoneyFormat
                              value={calculateResponse?.service ?? 0}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <div></div>
                      )}{" "}
                      {(calculateResponse?.percent_tax ?? "0") !== "0.00" ? (
                        <Grid container style={{ marginTop: 5 }}>
                          <Grid item xs={6} textAlign="left">
                            {"Tax (" + calculateResponse?.percent_tax + "%)"}
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <MoneyFormat value={calculateResponse?.tax ?? 0} />
                          </Grid>
                        </Grid>
                      ) : (
                        <div></div>
                      )}
                      {(calculateResponse?.roundingDif ?? "0") !== "0" ? (
                        <Grid container style={{ marginTop: 5 }}>
                          <Grid item xs={6} textAlign="left">
                            {"Total Transaksi"}
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <MoneyFormat
                              value={calculateResponse?.total ?? 0}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <div></div>
                      )}{" "}
                      {(calculateResponse?.roundingDif ?? "0") !== "0" ? (
                        <Grid container style={{ marginTop: 5 }}>
                          <Grid item xs={6} textAlign="left">
                            {"Pembulatan"}
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <MoneyFormat
                              value={calculateResponse?.roundingDif ?? 0}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <div></div>
                      )}{" "}
                      <Grid container style={{ marginTop: 5 }}>
                        <Grid item xs={6} textAlign="left">
                          <Typography fontWeight={"500"}>Total</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Typography fontWeight={"500"}>
                            <MoneyFormat
                              value={
                                parseInt(calculateResponse?.rounding) !== 0
                                  ? calculateResponse?.rounding
                                  : calculateResponse?.total
                              }
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                      <DefaultButton
                        title={isBtnDisabled ? "Mohon Tunggu" : "Pesan"}
                        disabled={isOpeningHour ? isBtnDisabled : true}
                        onClick={handleOrderCheck}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DefaultContainer>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cartData.filter(
      (data) => data.partnerID === ls.get("partnerID")
    ),
    userDetail:
      ls.get("sfToken") === null ||
      ls
        .get("sfToken")
        .includes(
          "dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz"
        ) ||
      ls
        .get("sfToken")
        .includes(
          "V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0="
        )
        ? null
        : state.userData[state.userData.length - 1],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAllFromCart: (addToast) => {
      dispatch(deleteAllFromCart(addToast));
    },
    decreaseQuantity: (item, addToast) => {
      dispatch(decreaseQuantity(item, addToast));
    },
    increaseQuantity: (item, addToast) => {
      dispatch(increaseQuantity(item, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartScreen);
